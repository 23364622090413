import React, { useState } from 'react';
import { useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';


// const mock = [
//   {
//     announcement:'{result[0].announcement}',
//     id: '',
//   },
//   {
//     announcement:'',
//     id: '',
//   },
// ];

// const axios = require('axios').default;
// const [initialized, setInitialized] = useState(false);
// const [windowLoaded, setWindowLoaded] = useState(false);


// let tokenData = '';


const Announcement = () => {

  
  
// let var1 =  window.location.hash.split('access_token=')[1];

// tokenData = var1.slice(0, -34);
// console.log('zzzzzz', tokenData);

// useEffect(() => {
//   if (!windowLoaded){
//   if (typeof window !== 'undefined' && window){
//   setWindowLoaded(true)
//   }
//   }
//   }, [windowLoaded]);

//   useEffect(() => {
//     if (!initialized && windowLoaded) {
//       window.onload = function() {
//         localStorage.setItem("token_Data",tokenData);
//       }
//     StorageHelpers.initiateStorage();
//       setInitialized(true);
//     }
//     }, [initialized, windowLoaded]);




//     axios
//       .get('https://ut3oa1hbvk.execute-api.ap-south-1.amazonaws.com/apiv1/npsypr/announcements')
//       .then((response) => {
//         if (response != null) {
//           localStorage.setItem('Announcement', JSON.stringify(response.data));
//         }
//       });

      // let mock = localStorage.getItem()

  return (
    <></>
  //   <Container>
  //     <Typography variant={'h4'} fontWeight={700} align={'center'}>
  //     Announcement
  //     </Typography>
  //     <br />
  //     <Grid item container marginLeft={130} xs={12} >
  //             <Button
  //               sx={{ height: 54, minWidth: 150 }}
  //               variant="contained"
  //               color="primary"
  //               size="medium"
  //               type="submit" 
  //               href='/UpdateMessage' 
  //             >
  //               ADD
  //             </Button>
  //           </Grid>

  //     <TableContainer component={Paper}>
  //       <Table sx={{ minWidth: 700 }} aria-label="">
  //         <TableHead sx={{ bgcolor: 'alternate.dark' }}>
  //           <TableRow>
  //             <TableCell>
  //               <Typography
  //                 variant={'caption'}
  //                 fontWeight={700}
  //                 sx={{ textTransform: 'uppercase' }}
  //               >
  //                 Date
  //               </Typography>
  //             </TableCell>
  //             <TableCell>
  //               <Typography
  //                 variant={'caption'}
  //                 fontWeight={700}
  //                 sx={{ textTransform: 'uppercase' }}
  //               >
  //                 Message
  //               </Typography>
  //             </TableCell>
  //             <TableCell>
  //               <Typography
  //                 variant={'caption'}
  //                 fontWeight={700}
  //                 sx={{ textTransform: 'uppercase' }}
  //               >
  //                 Action
  //               </Typography>
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {mock.map((item, i) => (
  //             <TableRow
  //               key={i}
  //               sx={{
  //                 '&:last-child td, &:last-child th': { border: 0 },
  //                 '&:nth-of-type(2n)': { bgcolor: 'alternate.main' },
  //               }}
  //             >
  //               <TableCell component="th" scope="row">
  //                 <Typography variant={'subtitle2'} fontWeight={700}>
  //                   {item.id}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography color={'text.secondary'} variant={'subtitle2'}>
  //                   {item.announcement}
  //                 </Typography>
  //               </TableCell>
  //               <TableCell>
             
  //                 <Button>
  //                   <Typography
  //                     color={'primary'}
  //                     variant={'subtitle2'}
  //                     fontWeight={700}
  //                     sx={{ cursor: 'pointer' }}
  //                   >
  //                      Delete
  //                   </Typography>

  //                 </Button>
  //               </TableCell>
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //       <Button>
  //                   <Typography
  //                     color={'primary'}
  //                     variant={'subtitle2'}
  //                     fontWeight={700}
  //                     sx={{ cursor: 'pointer' }}
  //                   >
  //                      ADD
  //                   </Typography>

  //                 </Button>
  //     </TableContainer>
  //   </Container>
  );
};

export default Announcement;